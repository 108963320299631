<style lang="scss">
  .update-user-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-user-modal">
    <section class="mb-2">
      <b-form-input v-model="user.name"
                    required
                    class="mb-1" placeholder="Enter name*"
      />
      <b-form-input v-model="user.email" required class="mb-1" type="email" placeholder="Enter email address*" />
    </section>

    <p>Roles</p>

    <section class="d-flex flex-wrap update-user-modal__roles">
      <label v-for="role in roles" :key="role.id" class="d-flex col-4">
        <b-form-checkbox
          v-model="user.roles"
          :value="role.id"
        />
        {{ role.name }}
      </label>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateUser()"
      >Update user</b-button>
    </section>

  </div>
</template>

<script>
  import RolesService from '../../../services/RolesService';
  import UserService from '../../../services/UserService';

  export default {
    components: {},
    props: {
      userExisting: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        showPassword: false,
        roles: [],
        user: {
          name: '',
          email: '',
          roles: [],
        },
      };
    },
    mounted() {
      this.setUserFromExisting();
      this.getRoles();
    },
    methods: {
      setUserFromExisting() {
        const {
          name, email, roles, uid,
        } = this.userExisting;
        const user = {
          name, email, roles, uid,
        };

        user.roles = this.userExisting.roles.map(role => role.id);
        this.user = user;
      },
      updateUser() {
        UserService.updateUser(this.user.uid, this.user).then(() => {
          this.$toast.success(`Updated user ${this.user.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(err => {
          const errorMessage = err && err.response && err.response.data && err.response.data.error;
          const msg = 'Could not update user, please refresh and try again';
          this.$toast.error(errorMessage ? err.response.data.error : msg, {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const emailReg = /\S+@\S+\.\S+/;
        const name = this.user.name.length > 0;
        const email = emailReg.test(this.user.email);

        return name && email;
      },
      getRoles() {
        RolesService.getRoles().then(res => {
          this.roles = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get roles, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
