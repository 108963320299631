<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <b-card class="" title="User Details">
      <b-card-text>This page shows details for your user. You can update the user and their password directly from this page.</b-card-text>
    </b-card>
    <section v-if="!loading">
      <div class="row">
        <div class="col-9">
          <b-tabs content-class="my-3">
            <b-tab title="Details" active>
              <b-card class="">
                <section class="row" v-if="user">
                  <section class="col-3">
                    <p class="font-weight-bolder">Name </p>
                    <p class="text-capitalize">{{user.name}}</p>
                  </section>
                  <section class="col-4">
                    <p class="font-weight-bolder">Email</p>
                    <p>{{user.email}}</p>
                  </section>
                  <section class="col-5">
                    <p class="font-weight-bolder">Roles</p>
                    <section class="d-flex flex-wrap update-user-modal__roles justify-content-left text-capitalize">
                      <p v-for="role in user.roles" :key="role.id" class="d-flex col-6 pl-0">
                        {{ role.name }}
                      </p>
                    </section>
                  </section>
                </section>
                <section v-else>Could not get user</section>
              </b-card>
            </b-tab>
            <b-tab title="Activities" v-if="$can('Read', 'Activity')">
              <model-activities model="user" :uid="user.uid"></model-activities>
            </b-tab>
          </b-tabs>

        </div>
        <div class="col-3" v-if="$can('Update', 'User') || $can('Delete', 'User')">
          <b-card>
            <p class="font-weight-bolder">Actions</p>
            <b-btn v-if="$can('Update', 'User')"
                   @click="showUserModal('modal-update-user')"
                   variant="primary"
                   class="mb-1 full-width">Update</b-btn>
            <b-btn v-if="$can('Update', 'User')"
                   @click="showUserModal('modal-password-user')"
                   variant="warning"
                   class="mb-1 full-width">Change Password</b-btn>
            <b-btn v-if="$can('Delete', 'User')"
                   @click="showUserModal('modal-delete-user')"
                   variant="danger"
                   class="mb-1 full-width">Delete</b-btn>
          </b-card>
        </div>
      </div>


      <b-modal id="modal-update-user" title="Update user" hide-footer>
        <update-user :user-existing="user" @refresh="refreshUser()" @close="closeModals()"></update-user>
      </b-modal>

      <b-modal id="modal-password-user" title="Change user password" hide-footer>
        <change-password-user :user="user" @refresh="refreshUser()" @close="closeModals()"></change-password-user>
      </b-modal>

      <b-modal id="modal-delete-user" title="Delete user" hide-footer>
        <delete-modal
          @close="closeModals()" @delete="deleteUser(user)"
          :subtitle="user.name"
          title="Are you sure you wish to delete user"></delete-modal>
      </b-modal>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading users"></b-spinner>
    </section>


  </div>
</template>
<!--eslint-enable-->

<script>
  import UserService from '../../services/UserService';
  import RolesService from '../../services/RolesService';
  import UpdateUser from './sections/UpdateUser.vue';
  import DeleteModal from '../../components/modals/DeleteModal.vue';
  import ChangePasswordUser from './sections/ChangePasswordUser.vue';
  import ModelActivities from '../activities/ModelActivities.vue';

  export default {
    name: 'Users',
    components: {
      DeleteModal,
      UpdateUser,
      ChangePasswordUser,
      ModelActivities,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        loading: true,
        user: null,
        roles: [],
      };
    },
    mounted() {
      this.getUser();
      this.getRoles();
    },
    methods: {
      refreshUser() {
        this.closeModals();
        this.getUser();
      },
      closeModals() {
        this.$bvModal.hide('modal-delete-user');
        this.$bvModal.hide('modal-update-user');
        this.$bvModal.hide('modal-password-user');
      },
      getUser() {
        this.loading = true;
        UserService.getUser(this.uid).then(res => {
          this.user = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get user, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          this.loading = false;
        });
      },
      showUserModal(modalName) {
        this.$bvModal.show(modalName);
      },
      deleteUser(user) {
        UserService.deleteUser(user.uid).then(() => {
          this.$toast.success(`Deleted user ${user.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$router.push({ name: 'users' });
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete user, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      getRoles() {
        RolesService.getRoles().then(res => {
          this.roles = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get roles, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
