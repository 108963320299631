<style lang="scss">
  .update-user-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-user-modal">
    <section class="mb-2">
      <validation-provider
        v-slot="validationContext"
        name="Password"
        :rules="rules"
      >
        <small class="text-primary">
          {{ info_text }}
        </small>
        <b-input-group class="mb-2">
          <b-form-input v-model="password.new_password" :state="getValidationState(validationContext)" required :type="showPassword ? 'text' : 'password'" placeholder="Enter new password*" />
          <b-input-group-append>
            <b-button @click="showPassword = !showPassword">
              <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
            </b-button>

          </b-input-group-append>
        </b-input-group>

        <b-input-group>
          <b-form-input v-model="password.new_password_confirm" required :type="showPassword2 ? 'text' : 'password'" placeholder="Re-enter new password*" />
          <b-input-group-append>
            <b-button @click="showPassword2 = !showPassword2">
              <i class="fas" :class="showPassword2 ? 'fa-eye' : 'fa-eye-slash'" />
            </b-button>

          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ validationContext.errors[0] }}</small>
      </validation-provider>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateUserPassword()"
      >Change password</b-button>
    </section>

  </div>
</template>

<script>
  import UserService from '../../../services/UserService';
  import ProfileService from "@/services/ProfileService";

  export default {
    components: {},
    props: {
      user: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        showPassword: false,
        showPassword2: false,
        password: {
          new_password: '',
          new_password_confirm: '',
        },
        rules: {required: true, min: 12,},
        info_text: "",
      };
    },
    mounted() {
      this.getProfile();
    },
    methods: {
      updateUserPassword() {
        UserService.updateUserPassword(this.user.uid, this.password).then(() => {
          this.$toast.success('Updated user password Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(err => {
          const errorMessage = err && err.response && err.response.data && err.response.data.error;
          const msg = 'Could not update user password, please refresh and try again';
          this.$toast.error(errorMessage ? err.response.data.error : msg, {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const password = this.password.new_password.length > 7;
        const password2 = this.password.new_password_confirm === this.password.new_password;

        return password && password2;
      },
      getProfile() {
        ProfileService.getProfile().then(res => {
          this.setRules(res.data.user_strong_password_required)
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get profile, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
      },
      setRules(strongPasswordRequired) {
        const strongPassText = strongPasswordRequired ? " Character patterns and repeated characters are not allowed." : "";
        this.rules = {required: true, min: 12, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
        this.info_text = "Required length `12`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>
